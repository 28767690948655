import { arrayNotEmpty } from "@/utils/functions";
import ViewClientBuildings from "../components/buildings/ViewClientBuildings.vue";

export const loginRoute = {
  name: "Login",
};

export const routes = {
  login: {
    name: "Login",
  },
  createAccount({ idNumber = null }) {
    const routeObject = {
      name: "CreateAccount",
      query: {},
    };
    if (!!idNumber) {
      routeObject.query["id-number"] = idNumber;
    }
    return routeObject;
  },
  createAccountWithQueries: ({ idNumber }) => {
    return {
      name: "CreateAccount",
    };
  },
  logout: {
    name: "Logout",
  },
  userAccountDetails: {
    name: "AccountDetails",
  },
  userProfileView: {
    name: "ViewProfile",
  },
  userLoggedInResetPassword: {
    name: "LoggedInResetPassword",
  },
  viewBills: {
    name: "ViewAccountBills",
  },
  viewBillsWithQuery: ({ tab }) => {
    return {
      name: "ViewAccountBills",
      query: {
        tab,
      },
    };
  },
  viewBusinesses: {
    name: "ViewBusinesses",
  },
  viewBuildings: {
    name: "ViewBuildings"
  },
  viewEnforcements:{
    name: "ViewEnforcements"
  },
  viewDrafts: {
    name: "ViewDrafts"
  },
  createBuildingApp:{
    name: "createBuildingApp"
  },
  viewClientBuildings: {
    name: "ViewClientBuildings"
  },
  viewRenovations: {
    name: "ViewRenovations"
  },
  viewDemolitions: {
    name: "ViewDemolitions"
  },
  viewVehicles: {
    name: "ViewVehicles",
  },
  viewHouses: {
    name: "ViewHouses",
  },
  renewSbp: {
    name: "RenewSbp",
  },

  payment: {
    name: "Payment",
    withQuery: ({
      billNumbersToPayAsString,
      directPayment = false,
      billsToPay = [],
    }) => {
      const query = {
        bill_numbers: billNumbersToPayAsString,
      };

      if (directPayment) {
        query.direct_payment = true;
      }

      const params = {};

      if (arrayNotEmpty(billsToPay)) {
        params.billsToPay = billsToPay;
      }
      return {
        name: "Payment",
        query,
        params,
      };
    },
  },

  generalBilling: {
    name: "GeneralBilling",
    withQuery({ billGenerated }) {
      return {
        name: "GeneralBilling",
        query: {
          bill_generated: billGenerated,
        },
      };
    },
  },

  viewProperties: {
    name: "ViewProperties",
  },

  propertyRateStatement: {
    name: "PropertyRateStatement",
    withQuery({ prn }) {
      return {
        name: "PropertyRateStatement",
        query: {
          prn,
        },
      };
    },
  },

  propertySearch: {
    name: "PropertySearch",
  },

  services: {
    name: "Services",
  },

  defaultService: {
    name: "DefaultService",
    withQuery({ serviceName, title, serviceCategory }) {
      return {
        name: "DefaultService",
        query: {
          "service-name": serviceName,
          title,
          serviceCategory,
        },
      };
    },
    withParams({ serviceName }) {
      return {
        name: "DefaultService",
        params: {
          serviceName,
        },
      };
    },
  },

  solidWasteCollection: {
    name: "SolidWasteCollection",
  },

  noisePollutionServices: {
    name: "NoisePollutionServices",
  },

  help: {
    name: "help",
  },

  faq: {
    name: "faq",
  },
};
