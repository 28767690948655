import {
  usePaginatedData,
  usePaginatedDataWithDebouncedSearch,
  userProfile,
  useMakeNetworkRequest,
  userDataLoading,
  countyDetails,
  useRouter,
} from "@/utils/vue_helpers";
import {
  stringNotEmpty,
  arrayNotEmpty,
  debounce,
  getResponseErrorType,
  convertJsDateToYMD,
  capitalizeWordsInSentence,
  objectNotEmpty,
} from "@/utils/functions";
import { computed, onMounted, reactive, ref, watch } from "vue";
import { searchDebounceTimeInMs, requestStatus } from "@/utils/constants";
import apiCall from "@/utils/api";
import { useMutation, useQuery, useQueryClient } from "@tanstack/vue-query";

const validPropertySearchQueryKey = "validPropertySearch";

export function useFetchProperties(my_variable) {

  console.log("useFetchProperties_variable")
  console.log(my_variable)


  const searchModel = ref("");
  const debouncedSearchModel = ref(null);

  const { paginationData: propertyData } = usePaginatedData();

  const customerNumber = computed(() => userProfile.value.customer_number);

  const url = computed(() => {
    let url = null
    if (my_variable == "attach_property_search"){
      url = `/api/client/property?customer_number=${''}&loadInitialData=${'Dont Load'}&page=${propertyData.paginationData.currentPage}`;

    }
    else{
      url = `/api/client/property?customer_number=${customerNumber.value}&page=${propertyData.paginationData.currentPage}`;

    }
    const debouncedSearchModelNotEmpty = stringNotEmpty(
      debouncedSearchModel.value
    );
    if (debouncedSearchModelNotEmpty) {
      if (my_variable == "attach_property_search") {
        url = `/api/client/property?customer_number=${''}&page=${propertyData.paginationData.currentPage}` + `&type=specificSearch&search=${debouncedSearchModel.value}`;
        
      }
      else{
        url = url + `&type=search&search=${debouncedSearchModel.value}`;

      }
      
    }
    return url;
  });

  const setDebouncedSearch = debounce((searchValue) => {
    debouncedSearchModel.value = searchValue;
  }, searchDebounceTimeInMs);

  watch(searchModel, (newVal) => {
    setDebouncedSearch(newVal);
  });

  watch(
    [customerNumber, url],
    async () => {
      propertyData.fetchData(url.value);
    },
    { immediate: true }
  );

  return {
    propertyData,
    searchModel,
  };
}

export function useGetLastPropertyLog() {
  async function getLastPropertyLog({ property }) {
    const url = `/api/client/property/get-last-property-log?prn=${property.prn}`;
    try {
      const latestPropertyLog = apiCall({
        url,
        method: "GET",
      });
      return latestPropertyLog;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  const getLastPropertyLogMutation = useMutation({
    mutationFn: getLastPropertyLog,
  });

  return getLastPropertyLogMutation;
}

export function useGenerateRatesBill() {
  const generateRatesBillRequestStatus = ref(requestStatus.NOT_SENDING);
  const generatingRatesBill = computed(() => {
    return generateRatesBillRequestStatus.value === requestStatus.SENDING;
  });

  async function generateRatesBill({ property, amount, remarks = null }) {
    const url = `/api/client/property/generate-property-rates-bill`;
    const requestData = {
      prn: property.prn,
      amount,
    };
    if (!!remarks) {
      requestData.remarks = remarks;
    }
    try {
      generateRatesBillRequestStatus.value = requestStatus.SENDING;
      const bill = await apiCall({
        url,
        data: requestData,
        method: "POST",
      });
      generateRatesBillRequestStatus.value = requestStatus.COMPLETE;
      return bill;
    } catch (error) {
      console.log(error);
      generateRatesBillRequestStatus.value = requestStatus.ERROR;
    }
  }

  return {
    generateRatesBill,
    generatingRatesBill,
  };
}

export function useFetchAllUserProperties() {
  const fetchAllPropertiesRequestStatus = ref(requestStatus.NOT_SENDING);
  const fetchingAllProperties = computed(() => {
    return fetchAllPropertiesRequestStatus.value === requestStatus.SENDING;
  });

  async function fetchAllUserProperties() {
    if (!userDataLoading.value) {
      const customerNumber = userProfile.value.customer_number;
      let url = `/api/client/property?customer_number=${customerNumber}&type=all`;
      try {
        fetchAllPropertiesRequestStatus.value = requestStatus.SENDING;
        const properties = await apiCall({
          url,
          method: "GET",
        });
        fetchAllPropertiesRequestStatus.value = requestStatus.COMPLETE;
        return properties;
      } catch (error) {
        fetchAllPropertiesRequestStatus.value = requestStatus.ERROR;
        console.log(error);
      }
    }
  }

  return {
    fetchAllPropertiesRequestStatus,
    fetchingAllProperties,
    fetchAllUserProperties,
  };
}

export function useGeneratePropertyRateStatement() {
  const { paginationData: statementData } = usePaginatedData();

  function getInitialDates() {
    const date = new Date();
    const currentYear = date.getFullYear();
    const from = `${currentYear}-01-01`;
    const to = convertJsDateToYMD(date);
    return {
      from,
      to,
    };
  }
  const dates = getInitialDates();

  const reportData = reactive({
    prn: "",
    fromDate: dates.from,
    toDate: dates.to,
  });

  const requestTypes = {
    JSON: "json",
    CSV: "csv",
  };

  function getPropertyRateUrl(requestType) {
    return `/api/client/property/get-property-rate-statement?type=${requestType}&prn=${reportData.prn}&date_from=${reportData.fromDate}&date_to=${reportData.toDate}`;
  }

  async function fetchPropertyRateStatement() {
    const url = getPropertyRateUrl(requestTypes.JSON);
    try {
      await statementData.fetchData(url);
    } catch (error) {
      console.log(error);
      statementData.fetchDataRequestStatus = getResponseErrorType(error);
    }
  }

  const csvData = reactive({
    data: [],
    fetchingCsvData: false,
    notEmpty: false,
    title: computed(() => {
      capitalizeWordsInSentence(countyDetails.value.name);
    }),
    fields: [
      "reference_date",
      "reference_no",
      "annual_rates_arrears",
      "ground_rent_arrears",
      "accumulated_penalty",
      "annual_rates",
      "ground_rent",
      "other_charges",
      "other_charges_arrears",
      "receipt_amount",
      "adjustment",
      "waiver",
      "current_balance",
      "receipt_no",
    ],
    labels: {
      reference_date: "Date",
      reference_no: "Ref No",
      annual_rates_arrears: "Annual Rates Arrears",
      ground_rent_arrears: "Ground Rent Arrears",
      accumulated_penalty: "Accumulated Penalty",
      annual_rates: "Annual Rates",
      ground_rent: "Ground Rent",
      other_charges: "Other Charges",
      other_charges_arrears: "Other Charges Arrears",
      receipt_amount: "Receipt Amount",
      adjustment: "Adjustment",
      waiver: "Waiver",
      current_balance: "Current Balance",
      receipt_no: "Receipt No",
    },
  });

  async function fetchPropertyRateCsv() {
    const url = getPropertyRateUrl(requestTypes.CSV);
    try {
      csvData.fetchingCsvData = true;
      const response = await apiCall({
        url,
        method: "GET",
      });
      csvData.notEmpty = arrayNotEmpty(response);
      csvData.data = response;
      csvData.fetchingCsvData = false;
    } catch (error) {
      csvData.fetchingCsvData = false;
    }
  }

  const { route, routeNotEmpty } = useRouter();
  onMounted(() => {
    if (routeNotEmpty.value) {
      const prnFromRoute = route.value.query["prn"];
      reportData.prn = prnFromRoute;
    }
  });

  return {
    fetchPropertyRateStatement,
    statementData,
    reportData,
    csvData,
    fetchPropertyRateCsv,
  };
}

export function useGeneratePropertySearch() {
  const queryClient = useQueryClient();

  async function generatePropertySearch({ prn }) {
    try {
      const response = await apiCall({
        url: "/api/client/property/generate-property-search",
        data: {
          prn,
        },
        method: "POST",
      });
      const responseNotEmpty = objectNotEmpty(response);
      if (responseNotEmpty) {
        const bill = response;
        return bill;
      }
      return null;
    } catch (error) {
      throw getResponseErrorType(error);
    }
  }

  const generatePropertySearchMutation = useMutation({
    mutationFn: generatePropertySearch,
    onSuccess() {
      queryClient.invalidateQueries([validPropertySearchQueryKey]);
    },
  });

  return generatePropertySearchMutation;
}

export function useGetValidPropertySearch() {
  async function getValidPropertySearch() {
    const url = `/api/client/property/get-valid-property-search`;
    try {
      const response = await apiCall({
        url,
        method: "GET",
      });
      return response;
    } catch (error) {
      throw getResponseErrorType(error);
    }
  }

  const propertyHasSearchCertMutation = useQuery({
    queryKey: [validPropertySearchQueryKey],
    queryFn: getValidPropertySearch,
    refetchOnWindowFocus: false,
  });

  return propertyHasSearchCertMutation;
}

export function useFetchAllProperties() {
  const {
    paginationData: propertiesData,
    searchModel,
    debouncedSearchModel,
    debouncedSearchModelNotEmpty,
  } = usePaginatedDataWithDebouncedSearch();

  const url = computed(() => {
    let url = `/api/client/property?type=property_search&page=${propertiesData.paginationData.currentPage}`;
    if (debouncedSearchModelNotEmpty.value) {
      url = url + `&search=${debouncedSearchModel.value}`;
    }
    return url;
  });

  watch(
    url,
    (newVal) => {
      propertiesData.fetchData(newVal);
    },
    {
      immediate: true,
    }
  );

  return {
    propertiesData,
    searchModel,
  };
}

export function useFetchPropertySearchFees() {
  async function fetchPropertySearchFees() {
    try {
      const response = await apiCall({
        url: "/api/client/property/get-property-search-fees",
        method: "GET",
      });
      return response;
    } catch (error) {
      throw getResponseErrorType(error);
    }
  }

  const propertySearchFeesQuery = useQuery({
    queryKey: ["propertySearchFees"],
    queryFn: fetchPropertySearchFees,
    cacheTime: 20 * 60 * 1000,
    refetchOnWindowFocus: false,
  });

  return propertySearchFeesQuery;
}
