<script setup>
import LoadingArea from "@/components/utils/LoadingArea.vue";
import ViewPropertiesTable from "@/components/buildings/tables/ViewPropertiesTable.vue";
import LabelledField from "@/components/utils/LabelledField.vue";
import Pagination from "@/components/utils/Pagination.vue";
import ViewProperty from "@/components/properties/view_properties/ViewProperty.vue";
import GenerateRatesBillDialog from "@/components/properties/view_properties/GenerateRatesBillDialog.vue";
import Dialog from "@/components/utils/Dialog.vue";
import { useFetchProperties } from "@/components/properties/properties_business_logic";
import { createBuildingAppData, fetchPropertyOwnerships, snackBarInfoFunction, addPropertyToDraft } from "../buildings_business_logic.js";
import { computed, ref, onMounted } from "vue";


import PreviousButton from "@/components/utils/PreviousButton.vue";
import NextButton from "@/components/utils/NextButton.vue";


const { propertyData, searchModel } = useFetchProperties("attach_property_search");

const property_ownerships_object = fetchPropertyOwnerships()
const property_ownerships = ref([])
const loadingButton = ref(false)


const emit = defineEmits(['goToNextStep', 'goToPreviousStep'])

const pages = {
  VIEW_PROPERTIES_TABLE: 0,
  VIEW_PROPERTY: 1,
};
const propertyOfInterest = ref(false);
const currentPage = ref(pages.VIEW_PROPERTIES_TABLE);
const goTo = {
  viewPropertiesTable() {
    currentPage.value = pages.VIEW_PROPERTIES_TABLE;
  },
  viewProperty(property) {
    (propertyToView.value = property),
      (currentPage.value = pages.VIEW_PROPERTY);
  },
};

const { snackbar_info } = snackBarInfoFunction()

const { create_building_aplication_data, building_additional_data } = createBuildingAppData()

const propertyToView = ref(null);
const propertyToViewNotEmpty = computed(() => {
  return !!propertyToView;
});

function onPaginationClicked(newPage) {
  propertyData.setNewPage(newPage);

}

function onViewPropertyClicked(property) {
  goTo.viewProperty(property);

}

function verifyAndGoToPreviousStep() {
  emit('goToPreviousStep')

}

function snackBarErrorMessage(message, time) {
    snackbar_info.snackbar_message = message
    snackbar_info.snackbar_timeout = time ? time : 5000
    snackbar_info.color = "error"
    snackbar_info.snackbar = true

}

function snackBarSuccessMessage(message, time) {
    snackbar_info.snackbar_message = message
    snackbar_info.snackbar_timeout = time ? time : 5000
    snackbar_info.color = "success"
    snackbar_info.snackbar = true

}

async function verifyAndGoToNextStep() {
  if (create_building_aplication_data.properties_array_data.length == 0) {
    snackbar_info.snackbar_message = "Please attach a property by clicking the 'Add Property' Button"
    snackbar_info.snackbar_timeout = 5000
    snackbar_info.snackbar = true
    return

  }

  let property_ownership = property_ownerships.value.find(element => element.id == create_building_aplication_data.land_tenure_id);

  if (property_ownership) {
    building_additional_data.property_ownership = property_ownership.ownership

  }

  loadingButton.value = true

  const add_property_to_draft = await addPropertyToDraft()

  if(add_property_to_draft.status == "success"){
    building_additional_data.draft_id = add_property_to_draft.draft_id
    emit('goToNextStep')

  }
  else{
    snackBarErrorMessage("Error occured Please contact admin")
    
  }

  loadingButton.value = false


}

function onAddPropertyToApplication(property) {
  propertyOfInterest.value = property;

  if (property.status.status_name != "Active") {
    snackbar_info.snackbar_message = "Only a property with Status of Active can be attached"
    snackbar_info.snackbar_timeout = 5000
    snackbar_info.snackbar = true
    return

  }

  if (create_building_aplication_data.properties_array_data.length > 0) {
    snackbar_info.snackbar_message = "Only 1 Property can be added at a time - Remove selected property to add another one."
    snackbar_info.snackbar_timeout = 5000
    snackbar_info.snackbar = true

  }
  else {
    setupDefaultsForProperty(property)
    create_building_aplication_data.properties_array_data.push(property)

  }

}

function setupDefaultsForProperty(property) {
  create_building_aplication_data.sub_county_id = property.sub_county_id
  create_building_aplication_data.ward_id = property.ward_id
  create_building_aplication_data.town_id = property.town_id
  create_building_aplication_data.land_tenure_id = property.property_ownership_id
  create_building_aplication_data.latitude = property.latitude
  create_building_aplication_data.longitude = property.longitude
  create_building_aplication_data.area_of_property = property.area

  building_additional_data.sub_county_name = property.sub_county ? property.sub_county.sub_county_name : ''
  building_additional_data.ward_name = property.town ? property.town.town_name : ''
  building_additional_data.town_name = property.ward ? property.ward.ward_name : ''

}

function onRemovePropertyFromApplication(property) {
  create_building_aplication_data.properties_array_data = []

}

onMounted(() => {
  property_ownerships_object.mutate(
    {},
    {
      onSuccess(property_ownerships_object) {
        property_ownerships.value = property_ownerships_object

      },
    }
  );


})

</script>

<template>
  <v-container fluid class="pa-0">
    <v-snackbar v-model="snackbar_info.snackbar" :timeout="snackbar_info.snackbar_timeout" top
      :color="snackbar_info.color">
      <span>{{ snackbar_info.snackbar_message }}</span>
    </v-snackbar>

    <v-tabs-items v-model="currentPage">
      <v-tab-item :value="pages.VIEW_PROPERTIES_TABLE">
        <v-row>
          <v-col cols="12">
            <div class="text-h4 text-center font-weight-bold primary--text">
              Plots
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <div class="text-h5 text-center font-weight-bold">
              Searching Criteria
            </div>
            
            <div style="width: 20%; margin: auto;">
              <v-list color="transparent">
                <v-list-item>
                  <v-list-item-title>
                    <div>I) Enter <b>PRN No</b></div>
                  </v-list-item-title>
                </v-list-item>

                <v-list-item>
                  <v-list-item-title>
                    <div>II) Enter <b>Plot No</b></div>
                  </v-list-item-title>
                </v-list-item>

              </v-list>

            </div>

          </v-col>

        </v-row>

        <v-row justify="center">
          <v-col cols="12" md="6">
            <LabelledField label="Search">
              <v-text-field class="background text-field" outlined dense v-model="searchModel" clearable />
            </LabelledField>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <LoadingArea :loading="propertyData.fetchingData">
              <v-container fluid class="pa-0">
                <v-row>
                  <v-col cols="12">
                    <ViewPropertiesTable :properties="propertyData.fetchedData"
                      :propertiesNotEmpty="propertyData.fetchedDataNotEmpty" :loading="propertyData.fetchingData"
                      @viewPropertyClicked="onViewPropertyClicked($event)"
                      @addPropertyToApplication="onAddPropertyToApplication($event)">
                    </ViewPropertiesTable>
                  </v-col>
                </v-row>
              </v-container>
            </LoadingArea>
          </v-col>
        </v-row>

        <v-row justify="end">
          <v-col cols="auto">
            <Pagination :totalNumberOfItems="propertyData.paginationData.total"
              :itemsPerPage="propertyData.paginationData.perPage" :currentPage="propertyData.paginationData.currentPage"
              @paginationClicked="onPaginationClicked" />
          </v-col>
        </v-row>

        <v-expand-transition>
          <v-row v-if="create_building_aplication_data.properties_array_data.length">
            <v-col cols="12">
              <h2>Added Properties</h2>
            </v-col>

            <v-col cols="12">
              <LoadingArea :loading="propertyData.fetchingData">
                <v-container fluid class="pa-0">
                  <v-row>
                    <v-col cols="12">
                      <ViewPropertiesTable componentAction="Remove Item"
                        :properties="create_building_aplication_data.properties_array_data"
                        :propertiesNotEmpty="propertyData.fetchedDataNotEmpty" :loading="propertyData.fetchingData"
                        @viewPropertyClicked="onViewPropertyClicked($event)"
                        @addPropertyToApplication="onRemovePropertyFromApplication($event)">
                      </ViewPropertiesTable>
                    </v-col>
                  </v-row>
                </v-container>
              </LoadingArea>
            </v-col>
          </v-row>

        </v-expand-transition>


        <v-expand-transition>
          <v-row justify="center" justify-md="end">
            <v-col>
              <PreviousButton @click="verifyAndGoToPreviousStep" />

            </v-col>

            <v-col>
              <NextButton @click="verifyAndGoToNextStep" :loading="loadingButton">
                <template #loadingText> Please Wait </template>
              </NextButton>

            </v-col>
          </v-row>

        </v-expand-transition>




      </v-tab-item>

      <v-tab-item :value="pages.VIEW_PROPERTY">
        <v-row>
          <v-col cols="12">
            <ViewProperty v-if="propertyToViewNotEmpty" :property="propertyToView" moduleInUse="Buildings Module"
              showGoBack @goBackClicked="goTo.viewPropertiesTable" />
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>




  </v-container>
</template>
